<template>
  <div>
    <div
      v-if="loading || (type === 'edit' && !editFormDataPopulated)"
      class="row justify-center items-center"
    >
      <q-spinner color="primary" size="3em" />
    </div>
    <div v-else>
      <q-form @submit="$emit('submit', formData)">
        <FormCard
          title="User Details"
          message="Enter the user's personal information below."
        >
          <template v-slot:inputs>
            <q-input
              filled
              v-model="formData.firstName"
              label="First Name"
              lazy-rules
              :rules="[
                val =>
                  (val && val.length > 1) || 'Enter a first name for this user.'
              ]"
              :disable="submitting || type !== 'create'"
              hint="Enter a first name for this user."
            />

            <q-input
              filled
              v-model="formData.lastName"
              label="Last Name"
              lazy-rules
              :rules="[
                val =>
                  (val && val.length > 1) || 'Enter a last name for this user.'
              ]"
              :disable="submitting || type !== 'create'"
              hint="Enter a last name for this user."
            />

            <q-input
              filled
              v-model="formData.email"
              label="Email Address"
              lazy-rules
              :rules="[
                val =>
                  (val && val.length > 1) ||
                  'Enter an email address for this user.'
              ]"
              :disable="submitting || type !== 'create'"
              hint="Enter an email address for this user."
            />

            <q-select
              filled
              v-model="formData.type"
              :options="typeOptions"
              label="Access Type"
              emit-value
              map-options
              :rules="[
                val => (val && val.length > 0) || 'Select an access type.'
              ]"
              :disable="submitting"
              hint="Admin users have access to all advertiser accounts, and have the ability to perform any dashboard action. Regular users must be given access to specific accounts, and have a limited set of actions available to them."
            />

            <q-select
              v-if="formData.type == 'regular'"
              filled
              label="Access Granted Advertiser Accounts"
              v-model="formData.advertiserAccountIds"
              :options="advertiserAccountOptions"
              multiple
              emit-value
              map-options
              :rules="[
                val =>
                  (val && val.length > 0) ||
                  'Select at least one account this user will have access to.'
              ]"
              :disable="submitting"
              hint="Select the accounts this user will have access to."
            >
              <template v-slot:option="scope">
                <q-item dense v-bind="scope.itemProps" class="q-pr-xl">
                  <q-item-section>
                    <q-item-label>
                      {{ scope.opt.label }}
                      <q-badge
                        v-if="
                          scope.opt.business_id !=
                            $store.state.business.record.id
                        "
                        color="gray-light"
                        text-color="gray-medium-dark"
                        :label="'Shared by ' + scope.opt.business_name"
                        style="font-size: 11px"
                        class="q-ml-sm"
                      />
                    </q-item-label>
                    <q-item-label caption>{{ scope.opt.value }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </template>
        </FormCard>

        <FormActionsSection>
          <template v-slot:primary-actions>
            <q-btn
              :label="type === 'create' ? 'Create User' : 'Save Changes'"
              type="submit"
              color="primary"
              :disable="submitting"
              :loading="submitting"
            />
            <q-btn
              flat
              label="Cancel"
              :to="{ name: 'AdvertiserUserAccessOverview' }"
              color="subtle"
              :disable="submitting"
            />
          </template>
        </FormActionsSection>
      </q-form>
    </div>
  </div>
</template>

<script>
import FormActionsSection from "@/components/UI/FormActionsSection";
import FormCard from "@/components/UI/FormCard";

export default {
  name: "UserAccountForm",
  components: { FormCard, FormActionsSection },
  props: {
    type: {
      type: String,
      required: true
    },
    userId: {
      type: String,
      required: false
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      loading: true,
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        type: "regular",
        advertiserAccountIds: []
      },
      typeOptions: [
        { label: "Admin", value: "admin" },
        { label: "Regular", value: "regular" }
      ],
      advertiserAccountOptions: []
    };
  },
  computed: {
    editFormDataPopulated() {
      return this.formData.email !== "";
    }
  },
  mounted() {
    if (this.type === "edit" && this.userId.length > 0) {
      this.getUserAccount(this.userId);
    }
    this.$store
      .dispatch("advertiserAccounts/GET_REQUEST", {})
      .then(advertiserAccounts => {
        if (advertiserAccounts.length > 0) {
          this.advertiserAccountOptions = this.generateSelectOptionsArray(
            advertiserAccounts,
            "name",
            "id",
            null,
            [
              {
                optionKey: "business_id",
                valueKey: "business_id"
              },
              {
                optionKey: "business_name",
                valueKey: "business_name"
              }
            ]
          );
          this.loading = false;
        }
      });
  },
  methods: {
    getUserAccount(userId) {
      this.$store
        .dispatch("users/GET_REQUEST", {
          params: {
            id: userId
          }
        })
        .then(data => {
          if (data.length > 0) {
            let userAccount = data[0];
            this.formData = {
              firstName: userAccount.first_name,
              lastName: userAccount.last_name,
              email: userAccount.email,
              type: userAccount.type,
              advertiserAccountIds:
                userAccount.type == "admin"
                  ? []
                  : userAccount.granted_access_advertiser_accounts
            };
          } else {
            this.$router.push({ name: "AdvertiserUserAccessOverview" });
          }
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
